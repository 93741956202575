<template>
<div>

  <!-- BANNER
================================================== -->
  <section class="top-position p-0">
    <div class="container-fluid px-0">
      <div class="slider-fade1 owl-carousel owl-theme w-100">
        <div class="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24" style="background-image: url('img/banner/banner-01.jpg')" data-overlay-dark="0">
          <div class="container pt-6 pt-md-0">
            <div class="row align-items-center">
              <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-6 position-relative">
                <h3><span class="badge bg-primary">Te Resolvemos tu Problema</span></h3>
                <h1 class="display-17 display-sm-11 display-md-9 display-lg-8 display-xl-4 mb-3 title text-secondary groteskUltra">¿Necesitas Dinero?</h1>
                <p class="lead w-95 w-md-90 w-lg-85 mb-2-2">Descubre lo fácil que es obtener un préstamo con nosotros. Trabajamos sin fiador, flexibilidad con la APC.</p>
                <router-link to="/gf-form">
                  <button type="button" class="btn btn-primary btn-lg text-white me-1">APLICA AQUÍ</button>
                  <button type="button" class="btn btn-outline-secondary btn-lg">Rápido y Fácil</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24" style="background-image: url('img/banner/banner-02.jpg')" data-overlay-dark="0">
          <div class="container pt-6 pt-md-0">
            <div class="row align-items-center">
              <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-6 position-relative">
                <h3><span class="badge bg-primary">Te Resolvemos tu Problema</span></h3>
                <h1 class="display-17 display-sm-11 display-md-9 display-lg-8 display-xl-4 mb-3 title text-secondary groteskUltra">Aprobación Inmediata</h1>
                <p class="lead w-95 w-md-90 w-lg-85 mb-2-2">Los mejores intereses del mercado y consolidamos tus deudas.</p>
                <router-link to="/gf-form">
                  <button type="button" class="btn btn-primary btn-lg text-white me-1">APLICA AQUÍ</button>
                  <button type="button" class="btn btn-outline-secondary btn-lg">Rápido y Fácil</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24" style="background-image: url('img/banner/banner-03.jpg')" data-overlay-dark="0" >
          <div class="container pt-6 pt-md-0">
            <div class="row align-items-center">
              <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-6 position-relative">
                <h3><span class="badge bg-primary">Te Resolvemos tu Problema</span></h3>
                <h1 class="display-17 display-sm-11 display-md-9 display-lg-8 display-xl-4 mb-3 title text-secondary groteskUltra">Aplica En Línea</h1>
                <p class="lead w-95 w-md-90 w-lg-85 mb-2-2">Fácil y Rápido.</p>
                <router-link to="/gf-form">
                  <button type="button" class="btn btn-primary btn-lg text-white me-1">APLICA AQUÍ</button>
                  <button type="button" class="btn btn-outline-secondary btn-lg">Rápido y Fácil</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="triangle-shape top-15 right-10 z-index-9 d-none d-md-block"></div>
    <div class="square-shape top-25 left-5 z-index-9 d-none d-xl-block"></div>
    <div class="shape-five z-index-9 right-10 bottom-15"></div>
  </section>
  <!-- SERVICES
  ================================================== -->
  <section class="p-0 overflow-visible service-block">
    <div class="container">
      <div class="row mt-n1-9">
        <div class="col-md-6 col-lg-4 mt-1-9">
          <div class="card card-style1 h-100 primary-shadow">
            <div class="card-body media p-1-6 p-sm-1-9">
              <i class="fas fa-meteor display-16"></i>
              <div class="media-body ms-4">
                <h4 class="h5">Rápido y Fácil</h4>
                <p class="mb-0">Llena el formulario aqui y serás contactado inmediatamente por uno de nuestros agentes.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mt-1-9">
          <div class="card card-style1 h-100 primary-shadow">
            <div class="card-body media p-1-6 p-sm-1-9">
              <i class="fas fa-list-ul display-16"></i>
              <div class="media-body ms-4">
                <h4 class="h5">Las Mejores Opciones</h4>
                <p class="mb-0">Nos aseguramos de brindarte las mejores opciones en el mercado.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 mt-1-9">
          <div class="card card-style1 h-100 primary-shadow">
            <div class="card-body media p-1-6 p-sm-1-9">
              <i class="fas fa-university display-16"></i>
              <div class="media-body ms-4">
                <h4 class="h5">Instituciones Financieras</h4>
                <p class="mb-0">Trabajamos con las mejores instituciones financieras para brindarte un respaldo sólido.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- ABOUT
  ================================================== -->
  <section>
    <div class="container pt-lg-4">
      <div class="row align-items-xl-center about-style1">
        <div class="col-lg-6 mb-6 mb-md-8 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
          <div class="position-relative">
            <div class="position-relative overflow-hidden pe-xl-7 pe-md-5">
              <img src="img/content/about-05.jpg" class="position-relative pb-1-9 z-index-1 rounded" alt="...">
            </div>
            <div class="shadow-block py-4 pe-4 ps-5 bg-secondary rounded">
              <i class="icon-trophy text-white opacity3"></i>
              <p class="mb-0 text-uppercase font-weight-500 text-white alt-font lh-base"><span class="d-block font-weight-600 display-6 no-letter-spacing">25+</span> años de <br> experiencia</p>
            </div>
            <div class="ani-left-right light-title text-primary opacity1 left-n10 bottom-75 alt-font d-none d-lg-block">nosotros</div>
          </div>
        </div>
        <div class="col-lg-6 wow fadeIn" data-wow-delay="400ms">
          <div class="ps-xl-4">
            <div class="mb-1-9">
              <h2 class="cd-headline clip display-20 display-md-18 font-weight-600">Los primeros dedicados a la promoción de productos bancarios <span class="p-0 text-primary d-block d-sm-inline-block d-md-block d-xl-inline-block">
                                        <b class="is-visible font-weight-600">Panamá</b>
                                    </span>
              </h2>
            </div>
            <p class="mb-1-9">Nos constituimos para brindarte asesoramiento financiero y guiarte hacia las mejores instituciones financieras del país.</p>
            <ul class="list-style1 mb-0 text-dark">
              <li>Ahorra tiempo – Completa el formulario <strong>en línea aquí.</strong></li>
              <li>Ahorra dinero – Ofreciendote las mejores <strong>opciones</strong> del país.</li>
              <li>Crece – Resuelve tus problemas <strong>financieros.</strong></li>
            </ul>
            <div class="media border-top border-color-light-gray mt-1-9 pt-1-9 align-items-center">
              <img src="img/content/sign.png" alt="...">
              <div class="media-body ms-4">
                <h4 class="h6">Rolando Gonzalez</h4>
                <p class="mb-0">(Gerente General)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="shape-five right-5 bottom-45 opacity3"></div>
  </section>

  <!-- COUNTER
  ================================================== -->
  <section class="p-0 wow fadeIn" data-wow-delay="100ms">
    <div class="container">
      <div class="right-container">
        <div class="row position-relative z-index-9 align-items-center py-1-9 py-lg-0">
          <div class="col-6 col-lg-3 d-none d-lg-block">
            <img src="img/content/about-02.jpg" alt="">
          </div>
          <div class="col-md-6 col-lg-8 offset-md-1 offset-lg-0 offset-xl-1 mb-1-9 mb-md-0 wow fadeInRight" data-wow-delay="100ms">
            <div class=" ps-4 ps-md-0">
              <h2 class="text-light">Forma parte de nuestro equipo de ventas</h2>
              <h5 class="text-light">Donde ofrecemos capacitación y excelentes comisiones.</h5>
              <h6 class="text-primary">Contáctenos: 202-6800 / 6900</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- WHY CHOOSE US
  ================================================== -->
  <section>
    <div class="container">
      <div class="row align-items-lg-center">
        <div class="col-lg-6 mb-5 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
          <div class="pe-xl-8">
            <div class="mb-1-9">
              <h3 class="display-20 display-md-18 font-weight-600">Nuestra gran inversión refleja nuestra meta en liderizar el mercado panameño.</h3>
            </div>
            <div id="accordion" class="accordion-style1">
              <div class="card mb-3">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      Servicio al cliente
                    </button>
                  </h5>
                </div>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                  <div class="card-body">
                    Con nuestra oficina ubicada estratégicamente podremos servirte de manera más eficiente. Más oficinas a nivel nacional pronto.
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Equipo de Trabajo.
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                  <div class="card-body">
                    Contamos con los diferente departamentos que permite una operación ágil y dinámica con constante seguimiento de cartera.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 position-relative wow fadeIn" data-wow-delay="400ms">
          <div class="row g-0 position-relative z-index-9">
            <div class="col-sm-6">
              <div class="d-block bg-primary px-3 px-sm-5 py-7 py-sm-8 py-lg-10 rounded-top position-relative overflow-hidden">
                <h4 class="mb-0 text-white">Servicio de Primera</h4>
                <i class="icon-genius text-white opacity3 mt-1 me-1-6 icon-lg right-bottom"></i>
              </div>
              <div class="mb-sm-5">
                <img src="img/content/why-choose-us-01.jpg" alt="">
              </div>
            </div>

            <div class="col-sm-6">
              <div class="mt-1-9 mt-sm-5">
                <img src="img/content/why-choose-us-02.jpg" alt="">
              </div>
              <div class="d-block bg-secondary px-3 px-sm-5 py-7 py-sm-8 py-lg-10 rounded-bottom position-relative overflow-hidden">
                <h4 class="mb-0 text-white">Cosultoría Financiera</h4>
                <i class="icon-hotairballoon text-white opacity3 mt-1 me-1-6 icon-lg right-bottom"></i>
              </div>
            </div>

          </div>
          <div class="ani-left-right light-title text-secondary opacity1 right-n10 bottom-75 bottom-xl-70 alt-font d-none d-lg-block">Porque Nosotros</div>
        </div>
      </div>
    </div>
    <div class="square-shape-two left-5 bottom-45 d-none d-xl-block"></div>
  </section>

  <!-- NEWS
  ================================================== -->
<news></news>


</div>
</template>
<script defer src="/js/owl.carousel.js"></script>
<script defer src="/js/owl.carousel.thumbs.js"></script>



<script>
import News from "@/views/News";

export default {
  name: "HomeContent.vue",
  components: {
    News
  }

}
</script>

<style scoped>

</style>
