<template>
  <header class="header-style2">

    <div class="navbar-default">

      <div class="container-fluid px-sm-2-9">
        <div class="row align-items-center">
          <div class="col-12 col-lg-12">
            <div class="menu_area alt-font">
              <nav class="navbar navbar-expand-lg navbar-light p-0">

                <div class="navbar-header navbar-header-custom">
                  <!-- start logo -->
                  <a :href="$router.resolve({name: 'Home'}).href">
                  <span class="navbar-brand logodefault"><img id="logo" src="/img/logos/logoGPHor.svg" alt="logo" style=""></span>
                  </a>

                  <!-- end logo -->
                </div>

                <div class="navbar-toggler"></div>

                <!-- menu area -->
                <ul class="navbar-nav align-items-lg-center ms-auto" id="nav">
                  <li><a :href="$router.resolve({name: 'Home'}).href">Inicio</a>
                  </li>
                  <li><a :href="$router.resolve({name: 'corp-info'}).href">Información Corporativa</a>
                  </li>
                </ul>
                <!-- end menu area -->

                <!-- start attribute navigation -->
                <div class="attr-nav align-items-lg-center ms-lg-auto">
                  <ul v-if="currentRouteName != 'GFForm'">
                    <li class="d-none d-xl-inline-block">
                      <router-link to="/gf-form">
                      <div class="butn primary medium text-white">Aplica Aquí</div>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <!-- end attribute navigation -->

              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  name: "Header.vue",

  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>

<style scoped>

</style>
