<template>
  <div>
    <div class="main-wrapper">
    <Header></Header>
    <home-content></home-content>
      <Footer></Footer>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomeContent from "../components/HomeContent";

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    HomeContent
  }
}
</script>
