<template>
  <div>
    <section>
      <div class="container z-index-9 position-relative">

        <div class="title-style1 wow fadeIn" data-wow-delay="100ms">
          <h2 class="h1">Noticias Recientes</h2>
        </div>
        <div class="row g-xl-5 mt-n1-9">
          <div class="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
            <article class="card card-style2 h-100">
              <div class="card-img position-relative">
                <img src="img/news/donboscoFair/thumbnail.jpg" class="card-img-top" alt="...">
              </div>
              <div class="card-body p-2-0 p-xl-2-4">
                <span class="text-secondary mb-2 d-block font-weight-500">Feb 14, 2022</span>
                <h3 class="h5 mb-4"><a href="#">Feria de Don Bosco.</a></h3>
                <button  @click="showDonBosco" type="button" class="butn-style1">
                  Ver Fotos
                </button>
              </div>
            </article>
          </div>
          <div class="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
            <article class="card card-style2 h-100">
              <div class="card-img position-relative">
                <img src="img/news/newPromoters/newPromotores.jpg" class="card-img-top" alt="...">
              </div>
              <div class="card-body p-2-0 p-xl-2-4">
                <span class="text-secondary mb-2 d-block font-weight-500">Feb 15, 2022</span>
                <h3 class="h5 mb-4"><a href="#">Capacitación a nuevos promotores.</a></h3>
              </div>
            </article>
          </div>
          <div class="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
            <article class="card card-style2 h-100">
              <div class="card-img position-relative">
                <img src="img/news/promotores.png" class="card-img-top" alt="...">
              </div>
              <div class="card-body p-2-0 p-xl-2-4">
                <span class="text-secondary mb-2 d-block font-weight-500">Dic 28, 2021</span>
                <h3 class="h5 mb-4"><a href="#">Trabaja con nosotros.</a></h3>
              </div>
            </article>
          </div>
          <div class="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
            <article class="card card-style2 h-100">
              <div class="card-img position-relative">
                <img src="img/news/credicorp-pres.jpg" class="card-img-top" alt="...">
              </div>
              <div class="card-body p-2-0 p-xl-2-4">
                <span class="text-secondary mb-2 d-block font-weight-500">Nov 16, 2021</span>
                <h3 class="h5 mb-4"><a href="#">Presentación Corporativa a Credicorp Bank.</a></h3>
                <button  @click="showMultiple" type="button" class="butn-style1">
                  Ver Fotos
                </button>
              </div>
            </article>
          </div>
          <div class="col-md-6 col-lg-4 mt-1-9 wow fadeIn" data-wow-delay="200ms">
            <article class="card card-style2 h-100">
              <div class="card-img position-relative">
                <img src="img/news/toma-arterial.jpg" class="card-img-top" alt="...">
                <a href="#!" class="category rounded">noticias</a>
              </div>
              <div class="card-body p-2-0 p-xl-2-4">
                <span class="text-secondary mb-2 d-block font-weight-500">Nov 22, 2021</span>
                <h3 class="h5 mb-4"><a href="#">Toma de Presión Arterial a Público.</a></h3>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="triangle-shape top-75 left-5 z-index-2 d-none d-xl-block"></div>
    </section>

<!--    Modal Code-->


    <div>
      <!-- all props & events -->
      <vue-easy-lightbox
          escDisabled
          moveDisabled
          :visible="visible"
          :imgs="imgs"
          :index="index"
          @hide="handleHide"
      ></vue-easy-lightbox>
    </div>

  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: "News",
  components: { VueEasyLightbox },
  data() {
    return {
      imgs: '',  // Img Url , string or Array of string
      visible: false,
      index: 0   // default: 0
    }
  },
  methods: {
    showMultiple() {
      this.imgs = [
        { title: '', src: 'img/news/credicorp/1.png' },
        { title: '', src: 'img/news/credicorp/2.png' },
        { title: '', src: 'img/news/credicorp/3.png' },
        { title: '', src: 'img/news/credicorp/4.png' },
      ]
      // allow mixing

      this.index = 1  // index of imgList
      this.show()
    },
    showDonBosco() {
      this.imgs = [
        { title: '', src: 'img/news/donboscoFair/1.jpg' },
        { title: '', src: 'img/news/donboscoFair/2.jpg' },
        { title: '', src: 'img/news/donboscoFair/3.jpg' },
        { title: '', src: 'img/news/donboscoFair/4.jpg' },
        { title: '', src: 'img/news/donboscoFair/5.jpg' },
      ]
      // allow mixing

      this.index = 1  // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }

}
</script>

