<template>
  <footer class="footer-style1 bg-dark">

    <div class="container">
      <div class="row">

        <div class="col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="200ms">
          <img src="img/logos/footer-light-logo.svg" class="mb-4 footerLogo" alt="..." />
          <p class="mb-1-9 text-white w-xxl-85">Te ayudamos con la mejor solución financiera.</p>
          <div class="social-icons2">
            <ul class="ps-0 mb-0">
              <li><a href="https://www.instagram.com/grupofuturo1/" target="_blank"><i class="fab fa-instagram"></i></a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-12 mb-1-9 pb-4 border-bottom border-color-light-white wow fadeIn" data-wow-delay="300ms">
              <div class="row">
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <i class="ti-mobile display-20 text-secondary"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h4 class="h5 text-white mb-1">Teléfonos</h4>
                      <p class="mb-0 text-white opacity8 small">202-6800 / 6900</p>
                      <p class="mb-0 text-white opacity8 small">6746-7906</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <i class="ti-email display-20 text-secondary"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h4 class="h5 text-white mb-1">Correo Electrónico</h4>
                      <p class="mb-0 text-white opacity8 small">info@grupofuturo.net</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <i class="ti-map-alt display-20 text-secondary"></i>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h4 class="h5 text-white mb-1">Oficina</h4>
                      <p class="mb-0 text-white opacity8 small">Ave. Central, al lado de la Basílica Don Bosco</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="400ms">
              <h3>Inicio</h3>
            </div>
            <div class="col-sm-6 col-lg-4 mb-1-9 mb-lg-0 wow fadeIn" data-wow-delay="500ms">
              <h3>Información Corporativa</h3>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="footer-bar wow fadeIn" data-wow-delay="200ms">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <p>&copy; <span class="current-year"></span> Grupo Futuro is Powered by
              <a href="https://wicon.io" class="text-secondary text-primary-hover">Wicon IO</a>
            </p>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: "Footer.vue"
}
</script>

<style scoped>

</style>
